.Statement {
    display: flex;
    /* backdrop-filter: blur(10px); */
    flex-direction: column;
    z-index: 1;
    align-items: center;
    padding: 30px;
    

}

.Statement > div {
    max-width: 800px;

}

/* .rotatingSayings {
    background: rgb(236, 166, 166)
    
} */