.BootCar {

    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    align-items: center;
}
.statement {
    font-size: larger;
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
    text-align: justify;
    text-align-last: justify;
}
.carousel {

    max-width: 800px;

}

.carousel-caption {
    border-radius: 10px;
    background: rgba(235,118,12,1);
    font-size: smaller;
    padding: 4px;
    

}
.carousel-caption > h5 {
    font-size: auto;
}
.accordion {
    top: -40px;
    --bs-accordion-active-color: black;
    --bs-accordion-active-bg: rgba(235,118,12,1);
    --bs-accordion-btn-icon: rgba(235,118,12,1);
    --bs-accordion-btn-focus-box-shadow: 0 0 0 0.05rem rgba(235,118,12,1);

}

.TopBar {

    background: rgb(218,232,232);
    background: linear-gradient(0deg, rgba(218,232,232,0) 0%, rgba(231,112,5,1) 8%, rgba(235,118,12,1) 100%, rgba(218,232,232,0) 100%); 
}

#basic-nav-dropdown {
    
}




