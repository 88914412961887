.missionstatement {
    display: flex;
    /* backdrop-filter: blur(10px); */
    flex-direction: column;
    z-index: 1;
    align-items: center;

    
}
.missionstatement > p {
    max-width: 800px;
}
